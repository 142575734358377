export default {
  "wst-title": "合宙TCP/UDP web测试工具",
  "wst-llcom-text": "串口助手llcom",
  "wst-llcom-tip": "可运行lua脚本的高自由度串口调试工具🛠。",
  "wst-connect-tcp": "打开 TCP",
  "wst-connect-udp": "打开 UDP",
  "wst-connect-tcp-ssl": "打开 TCP SSL",
  "wst-connect-close": "断开连接",
  "wst-bug-tip": "使用中遇到任何🕷️问题🕷️，就尽情给我们反馈吧 😺",
  "wst-bug-text": "反馈问题",
  "wst-client-close-tip": "点击断开客户端连接",
  "wst-client-empty": "未侦测到连接的设备",
  "wst-send-tip": "消息内容",
  "wst-send-btn": "发送",
  "wst-use-hex-on-tip": "消息内容为16进制编码",
  "wst-use-hex-off-tip": "消息内容为纯文本",
  "wst-use-nl-on-tip": "自动为消息添加换行符 \\r\\n",
  "wst-use-nl-off-tip": "点击后，会自动为消息添加换行符 \\r\\n",
  "wst-use-eb-on-tip": "收到客户端消息后，自动回显",
  "wst-use-eb-off-tip": "点击后，将打开自动回显",
  "wst-show-hex-on-tip": "日志面板同时显示16进制编码消息，点击即可隐藏它们",
  "wst-show-hex-off-tip": "点击后，日志面板会同时显示16进制编码消息",
  "wst-log-asc-tip": "日志行按照从早到晚顺序排列",
  "wst-log-desc-tip": "日志行按照从晚到早顺序排列",
};
